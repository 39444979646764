@import "../../../shared/scss/config.scss";

.Container {
  width: 100%;
  background-color: #000066;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.Toolbar {
  // background-color: $color-grey-dark;
  margin: 0 auto;
  height: 7rem;
  max-width: 120rem;
  width: 100%;
  // position: fixed;
  // top: 0;
  // left: 50vw;
  // transform: translateX(-50%);
  // z-index: 90;
  padding: 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    height: 100%;
  }

  .Logo {
    height: 80%;
  }

  @media (max-width: 499px) {
    .DesktopOnly {
      display: none;
    }
  }
}
