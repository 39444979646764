@import "../../shared/scss/config.scss";

.Container {
    // background-color: $color-grey-dark;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    padding: 8rem 0;
}

.HeaderContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h2 {
        max-width: 60rem;
        text-align: center;
        font-family: $header-font;
        font-size: 2.5rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
    }

    & > p {
        max-width: 60rem;
        text-align: center;
        margin-bottom: 2rem;
        padding: 0 1rem;
    }
}

.ContentContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > .Item {
        max-width: 20rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & > .Circle {
            border: 0.2rem solid $color-bg-kidsclub;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            margin-bottom: 2rem;
        }

        p {
            width: 90%;
        }
    }
}
