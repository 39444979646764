@import "../../shared/scss/config.scss";

.Events {
  width: 100%;
  background-color: $color-grey-dark;
  color: $color-white;
  padding: 3rem 2rem;

  h1 {
    font-family: $header-font;
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  p {
    text-align: center;
    width: 40rem;
    margin: 0 auto;
  }
}

.Event {
  // background-color: yellow;
  width: 100%;
  height: 25rem;

  display: flex;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    max-width: 66.66%;
    max-height: 66.66%;
  }
}

.EventContent {
  padding: 0 3rem;
  height: 66.66%;
  background-color: rgba($color-grey-dark, 0.8);
  position: absolute;
  left: 49%;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    width: 66.66%;
    font-family: $header-font;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
    padding: 0;
    width: 90%;
    text-align: left;
    margin-bottom: 1rem;
  }
}
