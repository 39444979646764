@import "/src/shared/scss/config.scss";
.Container {
  width: 60%;
  margin: auto;

  p {
    margin-top: 20px;
  }
  ul {
    margin: 20px;

    li {
      margin: 5px;
    }
  }
}
.Items {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;

  .item {
    max-width: 24rem;
    width: 100%;
    height: 33rem;
    text-align: center;

    h2 {
      margin-top: 20px;
    }
    p {
      text-align: justify;
      margin-top: 20px;
      line-height: 20px;
    }
    img {
      width: 50px;
    }
  }
}
