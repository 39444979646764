@import "../../../shared/scss/config.scss";

.Container {
  width: 100%;
  color: $color-white;
  // background-color: $color-grey-dark;
}

.HeaderContainer {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  color: $color-bg-training;
  // padding: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .HeaderItem {
    max-width: 25rem;
    width: 100%;
    // height: 20rem;
    // margin: 3rem 0;
    padding: 4rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    img {
      display: block;
      max-width: 50%;
      max-height: 50%;
      margin-bottom: 2rem;
      // border-radius: 5px;
    }

    h3 {
      font-family: $header-font;
      font-size: 1.6rem;
      text-transform: uppercase;
      text-align: center;
    }

    .HeaderArrow {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 1.5rem solid transparent;
      border-right: 1.5rem solid transparent;
      border-bottom: 2rem solid $color-bg-training;
    }
  }
}

.ContentContainer {
  min-height: 40rem;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 102, 0.9);
  padding: 3rem 0;

  .ContentItem {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
  }
}
