@import "../../shared/scss/config.scss";

.NewsList {
    width: 100%;
    padding: 3rem 1rem;
}

.News {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    max-width: 40rem;
    width: 100%;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 2rem;
    }

    h2 {
        font-family: $header-font;
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 2rem;
    }

    .Button {
        margin-bottom: 2rem;
    }
}
