@import './shared/scss/config.scss';

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px 10px/16px = 62.5%
  // font-size: 50%; // 1rem = 10px 10px/16px = 62.5%
}

body {
  font-family: $content-font;
  font-size: 1.2rem;
  // line-height: 1.6;
}

a {
  // color: $color-white;
  text-decoration: none;
}
