@import "../../shared/scss/config.scss";

.Container {
    width: 100%;
    // background-color: $color-grey-dark;
    padding: 8rem 1rem;
}

.HeaderContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 8rem;

    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        max-width: 50rem;
        text-align: center;
        font-family: $header-font;
        font-size: 2.5rem;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
    }
}

.ContentContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > .Item {
        max-width: 25rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
            height: 30%;
            // height: 8rem;
            margin-bottom: 3rem;
        }

        h3 {
            font-family: $header-font;
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 2rem;
        }

        p {
            width: 90%;
        }
    }
}
