@import "../../shared/scss/config.scss";

.Container {
  width: 100%;
  color: $color-white;
  // background-color: $color-grey-dark;
}

.HeaderContainer {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .HeaderItem {
    max-width: 25rem;
    width: 100%;
    height: 20rem;
    padding: 6rem 1rem;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    img {
      display: block;

      max-width: 100%;
      max-height: 100%;
    }

    .HeaderArrow {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 1.5rem solid transparent;
      border-right: 1.5rem solid transparent;
      border-bottom: 2rem solid $color-bg-product;
    }
  }
}

.ContentContainer {
  min-height: 40rem;
  height: 100%;
  width: 100%;
  background-color: $color-bg-product;
  padding: 3rem 0;

  .ContentItem {
    max-width: 120rem;
    width: 100%;
    margin: auto;
    margin-top: 40px;
  }
}
