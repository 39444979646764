@import "../../shared/scss/config.scss";

.Actions {
    width: 100%;

    & .Action:nth-child(odd) {
        background-color: $color-grey-dark;
        color: $color-white;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    & .Action:nth-child(even) {
        background-color: $color-white;
        color: $color-black;

        @media screen and (max-width: 600px) {
            flex-direction: column-reverse;
        }
    }
}

.Action {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        // padding: 3rem;
    }

    .ImageContainer {
        // background-color: yellow;
        height: 30rem;
        max-width: 50rem;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 600px) {
            height: 20rem;
        }

        img {
            display: block;
            max-width: 90%;
            max-height: 90%;
        }
    }

    .ContentContainer {
        // background-color: green;
        padding: 0 5rem;
        height: 30rem;
        max-width: 50rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


        h2 {
            font-family: $header-font;
            font-size: 2rem;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        p {
            width: 90%;
            margin-bottom: 2rem;
        }

        @media screen and (max-width: 600px) {
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            padding: 1rem;
            text-align: center;
        }
    }
}
