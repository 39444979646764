@import '../../../shared/scss/config.scss';

.Button {
    background-color: transparent;
    border: 0.2rem solid $color-black;
    border-radius: 0.5rem;
    color: $color-black;

    cursor: pointer;
    padding: 0.6rem 2rem;
    // margin: 10px;

    span {
        font-size: 1.5rem;
        font-family: $header-font;
    }

    &:hover {
        color: $primary-color;
        border-color: $primary-color;    
    }
}

.ButtonWhite {
    color: $color-white;
    border-color: $color-white;
}
