@import "../../shared/scss/config";

.FooterContainer {
    border-top: 0.5rem solid $color-black;
    padding-top: 3rem;

    p {
        padding-top: 3rem;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
    }
}

.Footer {
    border-top: 0.5rem solid $color-black;
    padding-top: 3rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 2rem;
}

.FooterMap {
    max-width: 40rem;
    width: 100%;

    h3 {
        font-family: $header-font;
        font-size: 1.6rem;
    }

    img {
        padding-top: 1rem;
        width: 25rem;
    }
}

.FooterAddress {
    max-width: 40rem;
    width: 100%;

    h3 {
        font-family: $header-font;
        font-size: 1.6rem;
    }
}

.FooterAddItem {
    padding-top: 1rem;
    img {
        display: inline-block;
        margin-right: 0.5rem;
    }

    a {
        text-decoration: none;
        color: $secondary-color;
        font-size: 1.2rem;
    }

    span {
        font-size: 1.2rem;
    }
}

.FooterLinks {
    max-width: 40rem;
    width: 100%;
}

.FooterPodcast {
    padding-bottom: 3rem;

    h3 {
        font-family: $header-font;
        font-size: 1.6rem;
    }

    img {
        padding: 1rem 0.2rem;
    }
}

.FooterSocial {
    h3 {
        font-family: $header-font;
        font-size: 1.6rem;
    }

    img {
        padding: 1rem 0.2rem;
    }
}
