@import '../../shared/scss/config.scss';

.Header {
    width: 100%;

    & > p {
        padding-top: 7rem;
        line-height: 23rem;
        text-align: center;
        font-family: $header-font;
        font-size: 5rem;
        color: $color-white;
        letter-spacing: 0.5rem;
        text-transform: uppercase;
    }
}
