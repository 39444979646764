@import "../../shared/scss/config.scss";

.Vendors {
    width: 100%;
    padding: 3rem 1rem;
    background-color: $color-grey-dark;
}

.Vendor {
    background-color: $color-white;
    position: relative;

    max-width: 40rem;
    width: 100%;
    height: 25rem;

    display: flex;
    justify-content: center;

    .ImageContainer {
        width: 70%;
        text-align: center;
        padding-top: 3rem;

        img {
            padding: 1rem 2rem;
            max-width: 100%;
            max-height: 10rem;
        }    
    }

    .TextContainer {
        width: 20rem;
        padding: 1rem;
        background-color: $color-black;
        color: $color-white;
        position: absolute;
        bottom: 0;
        right: 0;

        h2 {
            font-family: $header-font;
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 2rem;
        }
    }
}
