@import '../../../shared/scss/config.scss';

.Events {
    padding: 0 3rem;

    h2 {
        font-family: $header-font;
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    img {

    }
}