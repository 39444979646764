@import '../../../shared/scss/config.scss';

.Container {
    width: 100%;
    color: $color-white;
    background-color: $color-bg-kidsclub;
    padding: 8rem 0;
}

.HeaderContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 8rem;

    h2 {
        font-family: $header-font;
        font-size: 3rem;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        text-align: center;
    }
}

.ContentContainer {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    .ContentItem {
        width: 95%;

        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: flex-start;

        & > h3 {
            font-family: $header-font;
            margin-bottom: 1.5rem;
        }

        & > p {
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
        }

        &__Image {
            width: 100%;
            display: block;
            margin-bottom: 2rem;
        }

        &__Numbers {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 2rem;
            margin-bottom: 1.5rem;
        }

        &__Capacity {
            display: flex;
            justify-content: center;
            // width: 100%;
            // display: inline-block;
            // margin-bottom: 2rem;
        }

        h3 {
            font-family: $header-font;
            font-size: 1.8rem;
            text-transform: uppercase;
            text-align: center;
        }
    }
}
