@import "../../shared/scss/config.scss";

.center {
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  // background-color: yellow;

  h1 {
    max-width: 35rem;
    font-family: $header-font;
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  p {
    max-width: 35rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
}
