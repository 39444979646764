@import '../../../../shared/scss/config.scss';

.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;

    a {
        font-family: $header-font;
        font-size: 1.8rem;
        color: $color-grey-light;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
    }

    a:hover,
    a:active,
    a.active {
        color: $primary-color;
    }

    @media (min-width: 500px) {
        margin: 0;
        display: flex;
        // height: 100%;
        width: auto;
        align-items: center;

        a {
            color: white;
            text-decoration: none;
            height: 100%;
            padding: 16px 10px;
            border-bottom: 4px solid transparent;
        }

        a:hover,
        a:active,
        a.active {
            color: $primary-color;
        }
    }
}
