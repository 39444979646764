@import '../../shared/scss/config.scss';

.Values {
    width: 100%;
    // background-color: $color-grey-dark;
}

.Value {
    width: 100%;
    height: 34rem;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        height: 8rem;
        margin-bottom: 3rem;
    }

    h2 {
        font-family: $header-font;
        font-size: 2rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    p {
        width: 70%;
        text-align: center;
    }
}

