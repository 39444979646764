@import '../../shared/scss/config.scss';

.Timeline {
    width: 100%;
    padding: 5rem 0;

    display: grid;
}

.TimelineItem {
    grid-column: 2 / 4;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.FirstItem {
    width: 50%;
    height: 100%;
    border-right: 0.2rem dashed $color-black;
    position: relative;
}

.SecondItem {
    width: 50%;
    height: 100%;
    position: relative;
}

.FirstDot {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(0.5rem) translateX(0.6rem);
}

.SecondDot {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: $primary-color;
    top: 0;
    left: 0;
    transform: translateY(0.5rem) translateX(-0.6rem);
}

.EmptyColLeft {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
}

.EmptyColRight {
    grid-column: 4 / 5;
    grid-row: 1 / -1;
}
