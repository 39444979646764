@import "/src/shared/scss/config.scss";
@import "../../ProductList.module.scss";

.Container {
  min-height: 40rem;
  height: 100%;
  width: 100%;
  background-color: $color-bg-product;
  padding: 3rem 0;

  h2 {
    text-align: center;
  }

  .ContentIte {
    max-width: 120rem;
    width: 100%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    position: relative;
    flex-wrap: wrap;

    .Element {
      max-width: 15rem;
      width: 100%;
      height: 15rem;
      margin-bottom: 50px;

      p {
        margin-top: 20px;
        font-size: 10;
        text-align: center;
      }
    }

    .BBorder {
      max-width: 13rem;
      height: 13rem;
      border: 2px solid;
      border-color: #008b8b;
      border-radius: 50%;
      margin: auto;
      padding: 28px 28px 28px 28px;
    }

    img {
      width: 7rem;
      height: 7rem;
      transition: transform 0.2s;
    }
    img:hover {
      transform: scale(1.2);
    }
  }
}
