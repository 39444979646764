@import "/src/shared/scss/config.scss";
.Container {
  width: 65%;
  margin: auto;
  h2 {
    line-height: 30px;
  }
  p {
    text-align: justify;
    margin-top: 20px;
  }
}
.Items {
  max-width: 120rem;
  width: 100%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
  position: relative;
  .item {
    text-align: center;
    max-width: 23rem;
    width: 100%;
    height: 170px;

    p {
      margin-top: 15px;
      font-size: 20px;

      text-align: center;
    }

    .imgBorder {
      margin: auto;
      border: 3px solid black;
      border-radius: 50%;
      width: 85px;
      height: 85px;
      padding-top: 18px;
      background-color: white;
      img {
        width: 40px;
        transition: transform 0.2s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
  }
}
