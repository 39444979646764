@import "../../../shared/scss/config.scss";

.Container {
  width: 100%;
  color: $color-white;
  background-color: transparent;
}

.ContentContainer {
  // background-color: green;
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  .itemEdit {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    //    align-items: flex-start;
    // align-content: flex-start;
    flex-wrap: wrap;
    //   position: relative;

    .ContentItem__Image {
      height: 200px;
    }
  }
  .ContentItem {
    height: 400px;
    max-width: 34rem;
    margin-top: 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > h3 {
      font-family: $header-font;
      margin-bottom: 4rem;
      height: 40px;
    }

    & > p {
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    &__Image {
      width: 100%;
      display: block;
      margin-bottom: 2rem;
    }

    &__Numbers {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 2rem;
      margin-bottom: 1.5rem;
    }

    &__Capacity {
      display: flex;
      justify-content: center;
      // width: 100%;
      // display: inline-block;
      // margin-bottom: 2rem;
    }

    h3 {
      font-family: $header-font;
      font-size: 1.8rem;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
