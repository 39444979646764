.page_header {
  margin-bottom: 40px;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full_height {
  height: 100%;
}
.card.full_height {
  height: calc(100%-30px);
}

.card__header {
  text-transform: capitalize;
}
.card__header ~ .card__body {
  margin-top: 30px;
}
.card__footer {
  text-align: center;
  text-transform: capitalize;
}
