@import "../../../NewsList/NewsList.module.scss";
@import "../../../News/News.module.scss";
.htext {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.text {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}
